const themes = {
	light: {
		primaryTextColor: '#262626',
		secondaryTextColor: '#FFF',
		additionalTextColor: '#9D9D9D',
    primaryBorderColor: '#E4E4E4',
    buttonDefaultColor: '#000',
		buttonDisabledColor: '#A7A7A7',
		buttonDisabledTextColor: '#808080',
    inputDefaultColor: '#FFF',
    blankColor: '#F1F1F1'
	},
	dark: {
		primaryTextColor: '#262626',
		secondaryTextColor: '#363740',
		primaryBorderColor: '#E4E4E4',
    additionalTextColor: '#9D9D9D',
    primaryButtonColor: '#000',
    inputDefaultColor: '#FFF',
    blankColor: '#F1F1F1',
		buttonDisabledColor: '#A7A7A7',
		buttonDisabledTextColor: '#808080',
	}
}

export default themes