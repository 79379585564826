import { FC } from 'react'
import { FooterContainer } from './styled-components'
import { TProps } from './types'

const Footer: FC<TProps> = ({
  className,
  children
}) => {
  return <FooterContainer
    className={className}
  >
    {children}
  </FooterContainer>
}

export default Footer