import { FC } from 'react'
import { InputContainer } from './styled-components'
import { TProps } from './types'

const Input: FC<TProps> = ({
  placeholder,
  value,
  onChange,
  className
}) => {
  return <InputContainer
    className={className}
    placeholder={placeholder}
    value={value}
    onChange={(evt) => onChange(evt.target.value)}
  />
}

export default Input
