import styled from 'styled-components'
import { Button, Header, Footer, Input } from 'components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.blankColor};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
`

export const Content = styled.div`
  max-width: 340px;
  width: 100%;
  text-align: center;
`

export const AppButton = styled(Button)`
  margin-bottom: 40px;
`

export const AppInput = styled(Input)`
  margin-bottom: 30px;
`

export const Title = styled.div`
  font-family: 'Calluna';
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 16px;
  color: ${props => props.theme.primaryTextColor};
`

export const Description = styled.div`
  font-size: 16px;
  margin: 0 0 20px;
  font-weight: 400;
  color: ${props => props.theme.primaryTextColor};
`

export const AppHeader = styled(Header)`
  margin-bottom: 70px;
`

export const AppFooter = styled(Footer)`
  margin-bottom: 70px;
  text-align: center;
`

export const HeaderLogo = styled.img`
  max-width: 287px;
`

export const FooterLogo = styled.img`
  max-width: 155px;
`

export const CallUs = styled.div`
  display: flex;
  align-items: center;
`

export const CallUsIcon = styled.img`
  width: 24px;
  margin-right: 8px; 
`

export const CallUsTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
`

export const CallUsLink = styled.a`
  color: ${props => props.theme.primaryTextColor};
`