import styled from 'styled-components'

export const InputContainer = styled.input`
  height: 44px;
  background: ${props => props.theme.inputDefaultColor};
  border: 1px solid;
  border-color: ${props => props.theme.primaryBorderColor};
  border-radius: 8px;
  line-height: 1;
  padding: 0 16px;
  width: 100%;
  max-width: 340px;
  &::placeholder {
    color: ${props => props.theme.additionalTextColor};
    opacity: 1;
  }
`