import axios from 'axios'
const { REACT_APP_SERVER_URL } = process.env

const serverApi = axios.create({
  baseURL: REACT_APP_SERVER_URL
})

const requests = {
  getLink: (
    code: string
  ) => serverApi.get(`/claim/${code}`)
}

export default requests
