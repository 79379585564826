import { FC } from 'react'
import { HeaderContainer } from './styled-components'
import { TProps } from './types'

const Header: FC<TProps> = ({
  className,
  children
}) => {
  return <HeaderContainer
    className={className}
  >
    {children}
  </HeaderContainer>
}

export default Header