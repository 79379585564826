import styled, { css } from 'styled-components'

export const ButtonContainer = styled.button`
  background-color: ${props => props.theme.buttonDefaultColor};
  color: ${props => props.theme.secondaryTextColor};
  height: 48px;
  border-radius: 24px;
  border: none;
  width: 100%;
  max-width: 340px;
  line-height: 1;
  font-weight: 700;
  cursor: pointer;
  transition: opacity .3s;

  &:active,
  &:hover {
    opacity: 0.6;
  }

  ${props => props.disabled && css`
    cursor: not-allowed;
    background-color: ${props => props.theme.buttonDisabledColor};
    color: ${props => props.theme.buttonDisabledTextColor};

    &:active,
    &:hover {
      opacity: 1;
    }
  `}
`