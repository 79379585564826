import { FC, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import themes from 'themes'
import {
  Container,
  Title,
  HeaderLogo,
  FooterLogo,
  AppFooter,
  AppHeader,
  Description,
  Content,
  AppButton,
  AppInput,
  CallUs,
  CallUsTitle,
  CallUsIcon,
  CallUsLink
} from './styled-components'
import BrandIcon from 'images/level-lanvin-logo-black.svg'
import MessageIcon from 'images/message.png'
import LogoIcon from 'images/logo.png'
import serverApi from 'api'
import { AxiosError } from 'axios'

const App: FC = () => {
  const [ code, setCode ] = useState<string>('')

  const onSubmit = async () => {
    const codePrepared = code.replace(/-/g, "").toLocaleLowerCase()
    try {
      const response = await serverApi.getLink(codePrepared)
      if (response && response.data) {
        const { linkdroplink } = response.data
        window.location.href = linkdroplink
      }
    } catch (err) {
      const e = err as AxiosError
      alert(e.message)
    }    
  }

  return <ThemeProvider theme={themes.light}>
    <Container>
      <AppHeader>
        <HeaderLogo src={BrandIcon} />
      </AppHeader>
      <Content>
        <Description>
          Your Curb sneaker is ready!
        </Description>
        <Title>
          Enter the code to claim your wearable
        </Title>
        <Description>
          Remove the inner sole of your Curb sneaker to reveal the sticker with your 6-digit unique code
        </Description>
        <AppInput
          value={code}
          placeholder='Passcode'
          onChange={(value) => setCode(value)}
        />
        <AppButton
          title='Continue'
          disabled={!code || code.length !== 6}
          onClick={() => {
            onSubmit()
          }}
        />
      </Content>
      <AppFooter>
        <a
          rel="noreferrer"
          target="_blank"
          href='https://linkdrop.io/'
        >
          <FooterLogo src={LogoIcon} />
        </a>

        <CallUs>
          <CallUsIcon src={MessageIcon} alt='message'/>
          <CallUsTitle>
            Need help? Call us at <CallUsLink href="tel:+9718005383573">+971 800 538 3573</CallUsLink>
          </CallUsTitle>
        </CallUs>
      </AppFooter>
    </Container>
  </ThemeProvider>
}

export default App;
