import { FC } from 'react'
import { ButtonContainer } from './styled-components'
import { TProps } from './types'

const Button: FC<TProps> = ({
  title,
  onClick,
  className,
  disabled
}) => {
  return <ButtonContainer
    className={className}
    onClick={() => {
      if (disabled) { return }
      onClick && onClick()
    }}
    disabled={disabled}
  >
    {title}
  </ButtonContainer>
}

export default Button
